import React from 'react';

function SettingsNotifications() {
  return (
    <div className="w-full max-w-4xl mx-auto mt-14 px-3">
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-lg font-semibold leading-7">Notifications</h2>
        <p className="mt-1 text-lg leading-6 text-slate-500">
          We'll always let you know about important changes, but you pick what else you want to hear
          about.
        </p>

        <div className="mt-10 space-y-10">
          <fieldset>
            <legend className="text-lg font-semibold leading-6">By Email</legend>
            <div className="mt-6 space-y-6">
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="new_shows"
                    name="new_shows"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="text-lg leading-6">
                  <label htmlFor="comments" className="font-medium">
                    New Shows
                  </label>
                  <p className="text-slate-500">Get notified when new shows are announced.</p>
                </div>
              </div>
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="trends"
                    name="trends"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="text-lg leading-6">
                  <label htmlFor="candidates" className="font-medium text-black">
                    Trends
                  </label>
                  <p className="text-slate-500">
                    Get notified when shows are standing out in the community.
                  </p>
                </div>
              </div>
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="offers"
                    name="offers"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="text-lg leading-6">
                  <label htmlFor="offers" className="font-medium">
                    Offers
                  </label>
                  <p className="text-slate-500">
                    Get notified when showrunner has special offers and discounts.
                  </p>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
}

export default SettingsNotifications;
