import { useEffect, useState, useCallback, Fragment, useRef } from 'react';
import IMG_PLACEHOLDER from '../assets/images/placeholder1.png';
import PIN_ICON from '../assets/images/pin.png';
import { Source, Layer, Map, GeolocateControl, NavigationControl, useMap } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import MultiSelectDropdown from './../components/MultiSelectDropdown';
import MobileFilterCard from './../components/MobileFilterCard';
import HomeFooter from '../components/HomeFooter';
import Content from './../components/Content';
import {
  MEDIUM_TAGS,
  TOP_TAGS,
  STYLE_TAGS,
  LOCATION_TAGS,
  ON_VIEW_TAGS,
  INIT_MAP_VIEW_STATE,
  unclusteredPointLayer,
} from '../util/data-factory';
import { useClickAway } from 'react-use';
import { getShows } from '../util/util';
import { Link } from './../util/router';
import { useFiltersStore } from '../stores';
import { XMarkIcon } from '@heroicons/react/24/outline';

function IndexPage() {
  const boxRef = useRef(null);
  const mapRef = useRef(null);
  const [featureData, setFeatureData] = useState();
  const [shows, setShows] = useState([]);
  const [recommendedShows, setRecommendedShows] = useState([]);
  const [error, setError] = useState(null);
  const {
    showListView,
    open,
    selectedMediums,
    selectedLocation,
    selectedSubject,
    selectedStyles,
    sortedByRelevance,
    sortedByRelevanceToggle,
    setShowListView,
    setAllArtMedium,
    addToFilter,
    removeFromFilter,
    resetFilters,
    getCounts,
  } = useFiltersStore();

  const [currentKey, setCurrentKey] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  // TODO: Use and object with after pasring the data
  const [mapPopupInfo, setMapPopupInfo] = useState();
  const [mapImageInfo, setMapImageInfo] = useState();
  const [mapTopTagsInfo, setMapTopTagsInfo] = useState();

  const fetchShows = useCallback(async () => {
    try {
      const data = await getShows({
        open,
        sortedByRelevance,
        selectedMediums,
        selectedLocation,
        selectedSubject,
        selectedStyles,
      });
      setShows(data);
      const recommendedShows = data.filter(show => show.recommended === true);
      setRecommendedShows(recommendedShows);
    } catch (error) {
      console.error(error);
      setError('Failed to fetch shows');
    }
  }, [open, sortedByRelevance, selectedLocation, selectedMediums, selectedSubject, selectedStyles]);

  useEffect(() => {
    fetchShows();
  }, [fetchShows]);

  useClickAway(boxRef, () => {
    setCurrentFilter(null);
  });

  /**
   * Format filtered data as features to be
   * read as clusters by map-gl. Updates when filtered
   * data changes.
   */
  useEffect(() => {
    const formatted_data = shows.map(i => {
      let longitude = parseFloat(i.venues.longitude);
      let latitude = parseFloat(i.venues.latitude);
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
        properties: { ...i, lat: latitude, lng: longitude },
      };
    });

    setFeatureData({ type: 'FeatureCollection', features: formatted_data });
  }, [shows]);

  const handleSelectMedium = medium => {
    if (medium === 'All Art') {
      setAllArtMedium();
      return;
    }
    if (selectedMediums.has(medium)) {
      removeFromFilter('selectedMediums', medium);
    } else {
      addToFilter('selectedMediums', medium);
    }
  };

  const handleSelectLocation = location => {
    if (selectedLocation.has(location)) {
      removeFromFilter('selectedLocation', location);
    } else {
      addToFilter('selectedLocation', location);
    }
  };

  const handleSelectedSubject = subject => {
    if (selectedSubject.has(subject)) {
      removeFromFilter('selectedSubject', subject);
    } else {
      addToFilter('selectedSubject', subject);
    }
  };

  const handleSelectStyle = style => {
    if (selectedStyles.has(style)) {
      removeFromFilter('selectedStyles', style);
    } else {
      addToFilter('selectedStyles', style);
    }
  };

  const handleOnView = onView => {
    if (open.has(onView)) {
      removeFromFilter('open', onView);
    } else {
      addToFilter('open', onView);
    }
  };

  const counts = getCounts();
  const filterMap = {
    MEDIUM: {
      options: MEDIUM_TAGS,
      selected: selectedMediums,
    },
    'ON VIEW': {
      options: ON_VIEW_TAGS,
      selected: open,
    },
    'NEW YORK': {
      options: LOCATION_TAGS,
      selected: selectedLocation,
    },
    SUBJECT: {
      options: TOP_TAGS,
      selected: selectedSubject,
    },
    STYLE: {
      options: STYLE_TAGS,
      selected: selectedStyles,
    },
  };

  const handleFilterClick = filter => {
    if (currentKey === filter) {
      setCurrentKey(null);
      setCurrentFilter(null);
      return;
    }
    setCurrentKey(filter);
    setCurrentFilter(filterMap[filter]);
  };

  // TODO: Handle error
  //if (error) {
  //  return <div>Error: {error}</div>;
  //}

  /**
   * Handles logic when click event on cluster and markers
   */
  const onPinClick = useCallback(
    event => {
      const feature = event.features && event.features[0];
      if (!feature) return;
      const layer = feature.layer;

      if (layer.id === 'unclustered-point') {
        const mapboxSource = mapRef.current.getSource('my-data');
        console.debug(mapboxSource);
        event.originalEvent.stopPropagation();
        setMapPopupInfo(feature.properties);
        setMapImageInfo(JSON.parse(feature.properties.image_urls));
        setMapTopTagsInfo(JSON.parse(feature.properties.top_tags));
      } else {
        event.originalEvent.stopPropagation();
      }
    },
    [shows]
  );

  const closeShowMapDialog = () => {
    setMapPopupInfo(null);
    setMapImageInfo(null);
    setMapTopTagsInfo(null);
  };

  return (
    <Fragment>
      {showListView ? (
        <div className="relative">
          <main className="relative mt-2 md:mt-20 lg:mt-28">
            {/* large filter */}
            <div
              className="hidden lg:flex text-6xl leading-tight tracking-0.25 max-w-5xl justify-center flex-wrap gap-x-12 uppercase"
              style={{ margin: '0 auto' }}
            >
              <div
                className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
                  selectedMediums.size === 0 ? 'opacity-100' : 'opacity-25'
                }`}
              >
                <div
                  className="text-black font-medium"
                  onClick={() => handleSelectMedium('All Art')}
                >
                  All Art
                </div>
              </div>
              <div
                className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
                  selectedMediums.has('Paintings') ? 'opacity-100' : 'opacity-25'
                }`}
              >
                <div
                  className="text-black font-medium"
                  onClick={() => handleSelectMedium('Paintings')}
                >
                  Paintings
                </div>
              </div>
              <div
                className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
                  selectedMediums.has('Videos') ? 'opacity-100' : 'opacity-25'
                }`}
              >
                <div
                  className="text-center text-black font-medium"
                  onClick={() => handleSelectMedium('Videos')}
                >
                  Videos
                </div>
              </div>
              <div
                className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
                  selectedMediums.has('Multimedia') ? 'opacity-100' : 'opacity-25'
                }`}
              >
                <div
                  className="text-black font-medium"
                  onClick={() => handleSelectMedium('Multimedia')}
                >
                  Multimedia
                </div>
              </div>
              <div
                className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
                  selectedMediums.has('Photography') ? 'opacity-100' : 'opacity-25'
                }`}
              >
                <div
                  className="text-black font-medium"
                  onClick={() => handleSelectMedium('Photography')}
                >
                  Photography
                </div>
              </div>
              <div
                className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
                  selectedMediums.has('Sculptures') ? 'opacity-100' : 'opacity-25'
                }`}
              >
                <div
                  className="text-black font-medium"
                  onClick={() => handleSelectMedium('Sculptures')}
                >
                  Sculpture
                </div>
              </div>
              <div
                className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
                  selectedMediums.has('Drawings') ? 'opacity-100' : 'opacity-25'
                }`}
              >
                <div
                  className="text-black font-medium"
                  onClick={() => handleSelectMedium('Drawings')}
                >
                  Drawings
                </div>
              </div>
              <div
                className={`LargeFilter transition-opacity cursor-pointer duration-300 justify-center hover:opacity-100 items-start flex ${
                  selectedMediums.has('Installation') ? 'opacity-100' : 'opacity-25'
                }`}
              >
                <div
                  className="text-black font-medium"
                  onClick={() => handleSelectMedium('Installation')}
                >
                  Installations
                </div>
              </div>
            </div>

            <div className="sticky top-[calc(100vh-110px)] left-[calc(50vw-80px)] w-40 text-center">
              <button
                className="btn sm:btn-sm md:btn-md bg-red/80 rounded-3xl"
                onClick={() => setShowListView(false)}
              >
                Show Map
              </button>
            </div>

            <div className="sticky top-2 w-screen lg:hidden px-2 bg-[#f9f9f9]">
              <div className="grid grid-flow-col auto-cols-max py-3 gap-4 overflow-auto no-scrollbar">
                <button className="btn btn-md" onClick={() => sortedByRelevanceToggle()}>
                  SORT BY: TRENDING
                </button>

                <div className="indicator">
                  <span
                    className={`indicator-item badge bg-secondary-red text-white ${
                      counts.selectedMediumsCount === 0 && 'hidden'
                    }`}
                  >
                    {counts.selectedMediumsCount}
                  </span>
                  <button className="btn btn-md" onClick={() => handleFilterClick('MEDIUM')}>
                    MEDIUM
                  </button>
                </div>
                <div className="indicator">
                  <span
                    className={`indicator-item badge bg-secondary-red text-white ${
                      counts.openCount === 0 && 'hidden'
                    }`}
                  >
                    {counts.openCount}
                  </span>
                  <button className="btn btn-md" onClick={() => handleFilterClick('ON VIEW')}>
                    OPENING
                  </button>
                </div>
                <div className="indicator">
                  <span
                    className={`indicator-item badge bg-secondary-red text-white ${
                      counts.selectedLocationCount === 0 && 'hidden'
                    }`}
                  >
                    {counts.selectedLocationCount}
                  </span>
                  <button className="btn btn-md" onClick={() => handleFilterClick('NEW YORK')}>
                    NEW YORK
                  </button>
                </div>
                <div className="indicator">
                  <span
                    className={`indicator-item badge bg-secondary-red text-white ${
                      counts.selectedSubjectCount === 0 && 'hidden'
                    }`}
                  >
                    {counts.selectedSubjectCount}
                  </span>
                  <button className="btn btn-md" onClick={() => handleFilterClick('SUBJECT')}>
                    SUBJECT
                  </button>
                </div>
                <div className="indicator">
                  <span
                    className={`indicator-item badge bg-secondary-red text-white ${
                      counts.selectedStylesCount === 0 && 'hidden'
                    }`}
                  >
                    {counts.selectedStylesCount}
                  </span>
                  <button className="btn btn-md" onClick={() => handleFilterClick('STYLE')}>
                    STYLE
                  </button>
                </div>
              </div>

              {currentFilter && (
                <MobileFilterCard
                  ref={boxRef}
                  currentKey={currentKey}
                  filterMap={filterMap}
                  options={currentFilter.options}
                />
              )}
            </div>

            {/* sub filters */}
            <div className="hidden top-10 lg:flex flex-wrap justify-center items-center text-base pt-0 lg:pt-5">
              <button
                className="btn btn-md bg-transparent mr-2"
                onClick={() => sortedByRelevanceToggle()}
              >
                SORT BY: TRENDING
              </button>

              <MultiSelectDropdown
                label="Medium"
                tags={MEDIUM_TAGS}
                selectedTags={selectedMediums}
                count={counts.selectedMediumsCount}
                handleSelect={handleSelectMedium}
              />
              <MultiSelectDropdown
                label="Opening"
                tags={ON_VIEW_TAGS}
                selectedTags={open}
                count={counts.openCount}
                handleSelect={handleOnView}
              />
              <MultiSelectDropdown
                label="New York"
                tags={LOCATION_TAGS}
                selectedTags={selectedLocation}
                count={counts.selectedLocationCount}
                handleSelect={handleSelectLocation}
              />
              <MultiSelectDropdown
                label="Subject"
                tags={TOP_TAGS}
                selectedTags={selectedSubject}
                count={counts.selectedSubjectCount}
                handleSelect={handleSelectedSubject}
              />
              <MultiSelectDropdown
                label="style"
                tags={STYLE_TAGS}
                selectedTags={selectedStyles}
                count={counts.selectedStylesCount}
                handleSelect={handleSelectStyle}
              />
            </div>

            <div className="flex justify-center p-5">
              <div className="flex justify-between bg-green/90 p-2 px-5 rounded-lg w-full md:w-96">
                <div>
                  <div className="text-base md:text-xl">
                    <strong className="text-white">{shows.length}</strong> shows found
                  </div>
                  <div className="text-sm md:text-base text-slate-200">
                    {recommendedShows.length} recommended shows in this search
                  </div>
                </div>
                <div>
                  <button className="btn btn-ghost" onClick={() => resetFilters()}>
                    Clear all{' '}
                  </button>
                </div>
              </div>
            </div>

            {/* content */}
            <Content shows={shows} />
          </main>

          <HomeFooter hideMobile={true} />
        </div>
      ) : (
        <div className="w-screen h-auto">
          <div className="flex w-screen h-auto lg:hidden px-2 bg-transparent pt-14">
            <div className="grid grid-flow-col auto-cols-max m-auto py-3 gap-4 overflow-auto no-scrollbar">
              <button className="btn btn-md" onClick={() => sortedByRelevanceToggle()}>
                SORT BY: TRENDING
              </button>
              <div className="indicator">
                <span
                  className={`indicator-item badge bg-secondary-red text-white ${
                    counts.selectedMediumsCount === 0 && 'hidden'
                  }`}
                >
                  {counts.selectedMediumsCount}
                </span>
                <button className="btn btn-md" onClick={() => handleFilterClick('MEDIUM')}>
                  MEDIUM
                </button>
              </div>
              <div className="indicator">
                <span
                  className={`indicator-item badge bg-secondary-red text-white ${
                    counts.openCount === 0 && 'hidden'
                  }`}
                >
                  {counts.openCount}
                </span>
                <button className="btn btn-md" onClick={() => handleFilterClick('ON VIEW')}>
                  OPENING
                </button>
              </div>
              <div className="indicator">
                <span
                  className={`indicator-item badge bg-secondary-red text-white ${
                    counts.selectedLocationCount === 0 && 'hidden'
                  }`}
                >
                  {counts.selectedLocationCount}
                </span>
                <button className="btn btn-md" onClick={() => handleFilterClick('NEW YORK')}>
                  NEW YORK
                </button>
              </div>
              <div className="indicator">
                <span
                  className={`indicator-item badge bg-secondary-red text-white ${
                    counts.selectedSubjectCount === 0 && 'hidden'
                  }`}
                >
                  {counts.selectedSubjectCount}
                </span>
                <button className="btn btn-md" onClick={() => handleFilterClick('SUBJECT')}>
                  SUBJECT
                </button>
              </div>
              <div className="indicator">
                <span
                  className={`indicator-item badge bg-secondary-red text-white ${
                    counts.selectedStylesCount === 0 && 'hidden'
                  }`}
                >
                  {counts.selectedStylesCount}
                </span>
                <button className="btn btn-md" onClick={() => handleFilterClick('STYLE')}>
                  STYLE
                </button>
              </div>
            </div>

            {currentFilter && (
              <MobileFilterCard
                ref={boxRef}
                currentKey={currentKey}
                filterMap={filterMap}
                options={currentFilter.options}
                floating={true}
              />
            )}
          </div>
          <div className="hidden h-[15vh] lg:flex flex-wrap justify-center items-center text-base pt-20">
            <MultiSelectDropdown
              label="Medium"
              tags={MEDIUM_TAGS}
              selectedTags={selectedMediums}
              count={counts.selectedMediumsCount}
              handleSelect={handleSelectMedium}
            />
            <MultiSelectDropdown
              label="Opening"
              tags={ON_VIEW_TAGS}
              selectedTags={open}
              count={counts.openCount}
              handleSelect={handleOnView}
            />
            <MultiSelectDropdown
              label="New York"
              tags={LOCATION_TAGS}
              selectedTags={selectedLocation}
              count={counts.selectedLocationCount}
              handleSelect={handleSelectLocation}
            />
            <MultiSelectDropdown
              label="Subject"
              tags={TOP_TAGS}
              selectedTags={selectedSubject}
              count={counts.selectedSubjectCount}
              handleSelect={handleSelectedSubject}
            />
            <MultiSelectDropdown
              label="style"
              tags={STYLE_TAGS}
              selectedTags={selectedStyles}
              count={counts.selectedStylesCount}
              handleSelect={handleSelectStyle}
            />
          </div>
          <div className="relative h-[82vh] md:h-[80vh]">
            <div className="absolute z-[2] left-5 md:left-[calc(50vw-212px)] flex justify-center p-5">
              <div className="flex justify-between bg-green/90 p-2 px-[1.2rem] rounded-lg w-full md:w-96">
                <div>
                  <div className="text-base md:text-xl">
                    <strong className="text-white">{shows.length}</strong> shows found
                  </div>
                  <div className="text-sm md:text-base text-slate-200">
                    {recommendedShows.length} recommended shows in this search.
                  </div>
                </div>
                <div>
                  <button className="btn btn-ghost" onClick={() => resetFilters()}>
                    Clear all{' '}
                  </button>
                </div>
              </div>
            </div>
            <div className="sticky top-[calc(100vh-110px)] left-[calc(50vw-80px)] w-40 z-40 text-center">
              <button
                className="btn sm:btn-sm md:btn-md bg-red/80 rounded-3xl"
                onClick={() => setShowListView(true)}
              >
                Show List
              </button>
            </div>

            {mapImageInfo && (
              <div className="absolute top-[calc(55vh-200px)] right-3 h-42 w-42 z-40">
                <div
                  className="absolute top-3 right-3 z-30 w-6 h-6 p-1 bg-white rounded-xl cursor-pointer"
                  onClick={closeShowMapDialog}
                >
                  <XMarkIcon className="my-auto" />
                </div>
                <div className="bg-white h-auto rounded-xl overflow-hidden relative p-2">
                  <div className="h-40 md:h-60 w-40 md:w-60">
                    <img
                      className="h-[95%] mx-auto object-cover"
                      alt="_image"
                      src={mapImageInfo.length > 0 ? mapImageInfo[0] : IMG_PLACEHOLDER}
                    />
                  </div>

                  <Link href="/show/[id]" to={`/show/${mapPopupInfo.show_id}`}>
                    <div className="flex flex-col mt-2 p-2 text-wrap w-40 md:w-60 cursor-pointer">
                      <p className="uppercase">{mapPopupInfo.show_title}</p>
                      <p className="text-sm text-red">{mapTopTagsInfo?.join(' | ')}</p>
                      <p className="text-sm text-slate-500">
                        {mapPopupInfo.start_date} - {mapPopupInfo.end_date}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            )}
            <Map
              ref={mapRef}
              initialViewState={INIT_MAP_VIEW_STATE}
              mapLib={maplibregl}
              mapStyle="https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json"
              interactiveLayerIds={[unclusteredPointLayer.id]}
              minZoom={1.25}
              maxZoom={20}
              onClick={onPinClick}
              onLoad={async () => {
                const map = mapRef.current.getMap();
                if (!map.hasImage('pin-icon')) {
                  const image = await map.loadImage(PIN_ICON);
                  map.addImage('pin-icon', image.data);
                }
              }}
            >
              <GeolocateControl />
              <NavigationControl />
              <Source
                id="my-data"
                type="geojson"
                data={featureData}
                clusterMaxZoom={11}
                clusterRadius={100}
              >
                <Layer {...unclusteredPointLayer} />
              </Source>
            </Map>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default IndexPage;

// Other Map styles
// mapStyle="https://raw.githubusercontent.com/go2garret/maps/main/src/assets/json/openStreetMap.json" // https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json
// mapStyle="https://external.xx.fbcdn.net/maps/vt/style/grayscale/"
