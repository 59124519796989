import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from './../util/auth';
//import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';

function SettingsGeneral(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => {
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: 'success',
          message: 'Your profile has been updated',
        });
      })
      .catch(error => {
        if (error.code === 'auth/requires-recent-login') {
          props.onStatus({
            type: 'requires-recent-login',
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: 'error',
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <div className="w-full max-w-4xl mx-auto mt-14 px-3">
      <div className="space-y-12">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-3xl md:text-4xl font-semibold leading-7">Showrunner Account</h2>
            <p className="mt-1 text-lg leading-6 text-slate-500">
              Contact info and personal details can be edited.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="name" className="block text-lg font-medium leading-6">
                  Name
                </label>
                <div className="mt-2">
                  <input
                    className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 mt-1"
                    name="name"
                    type="text"
                    placeholder="Name"
                    defaultValue={auth.user.name}
                    ref={register({
                      required: 'Please enter your name',
                    })}
                  />
                  {errors.name && (
                    <p className="mt-1 text-lg text-left text-red-600">{errors.name.message}</p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="email" className="block text-lg font-medium leading-6">
                  Email addres
                </label>
                <div className="mt-2">
                  <input
                    className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 mt-1"
                    name="email"
                    type="email"
                    placeholder="Email"
                    defaultValue={auth.user.email}
                    ref={register({
                      required: 'Please enter your email',
                    })}
                  />
                  {errors.email && (
                    <p className="mt-1 text-lg text-left text-red-600">{errors.email.message}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label htmlFor="about" className="block text-lg font-medium leading-6">
                  Write a few sentences about yourself.
                </label>
                <div className="mt-2">
                  <textarea
                    className="block w-full rounded-md border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 mt-1 p-3"
                    id="about"
                    name="about"
                    rows={3}
                    defaultValue={auth.user.about}
                    ref={register({})}
                  />
                </div>
              </div>
            </div>

            <div className="mt-3 flex items-center justify-end gap-x-6">
              <button
                type="submit"
                className="rounded-md bg-secondary-red px-10 py-2 text-lg text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2"
                disabled={pending}
              >
                {pending ? '...' : 'Save'}
              </button>
            </div>
          </div>
        </form>

        <div className="border-b border-gray-900/10 pb-12">
          <PasswordUpdate onStatus={props.onStatus} />
        </div>
      </div>
    </div>
  );
}

export default SettingsGeneral;

const PasswordUpdate = props => {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors, reset, getValues } = useForm();

  const onSubmit = data => {
    // Show pending indicator
    setPending(true);

    auth
      .updatePassword(data.pass)
      .then(() => {
        // Clear form
        reset();
        // Set success status
        props.onStatus({
          type: 'success',
          message: 'Your password has been updated',
        });
      })
      .catch(error => {
        if (error.code === 'auth/requires-recent-login') {
          // Update state to show re-authentication modal
          props.onStatus({
            type: 'requires-recent-login',
            // Resubmit after reauth flow
            callback: () => onSubmit({ pass: data.pass }),
          });
        } else {
          // Set error status
          props.onStatus({
            type: 'error',
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <div>
            Password
            <input
              className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 mt-1"
              name="pass"
              type="password"
              placeholder="Password"
              autoComplete="new-password"
              ref={register({
                required: 'Please enter a password',
              })}
            />
            {errors.pass && (
              <p className="mt-1 text-sm text-left text-red-600">{errors.pass.message}</p>
            )}
          </div>
        </div>
        <div className="sm:col-span-3">
          <div className="">
            <div className="flex justify-between">
              Confirm New Password
              <button
                type="submit"
                className="underline underline-offset-4 text-secondary-red"
                disabled={pending}
              >
                {pending ? '...' : 'Update Password'}
              </button>
            </div>

            <input
              className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 mt-1"
              name="confirmPass"
              type="password"
              autoComplete="new-password"
              placeholder="Confirm Password"
              ref={register({
                required: 'Please enter your new password again',
                validate: value => {
                  if (value === getValues().pass) {
                    return true;
                  } else {
                    return "This doesn't match your password";
                  }
                },
              })}
            />
            {errors.confirmPass && (
              <p className="mt-1 text-sm text-left text-red-600">{errors.confirmPass.message}</p>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};
