import { useCallback } from 'react';

/**
 * custom hook to share content using the Web Share API.
 * @returns
 */
const useWebShare = () => {
  const shareContent = useCallback(async ({ files, title, text, url }) => {
    if (!files || files.length === 0) {
      console.warn('No files selected to share.');
    }

    if (!navigator.canShare) {
      console.error("Your browser doesn't support the Web Share API.");
      return;
    }

    const shareData = { title, text, url };

    if (files && files.length > 0) {
      if (navigator.canShare({ files })) {
        shareData.files = files;
      } else {
        console.error("Your system doesn't support sharing these files.");
        return;
      }
    }

    try {
      await navigator.share(shareData);
      console.log('Shared successfully!');
    } catch (error) {
      console.error(`Error sharing content: ${error.message}`);
    }
  }, []);

  return { shareContent };
};

export default useWebShare;
