import React from 'react';

function LegalTerms(props) {
  return (
    <div className="prose prose-a:text-blue-600 max-w-none">
      <h1>Terms of Service</h1>

      <p>
        <strong>Last Updated:</strong> September 28, 2024
      </p>

      <p>
        Welcome to Showrunner! These Terms of Service ("Terms") govern your use of our website
        located at <a href="http://www.showrunner.co">www.showrunner.co</a> (the "Site") and any
        related services provided by Showrunner ("we", "us", "our"). By accessing or using the Site,
        you agree to be bound by these Terms. If you do not agree with these Terms, please do not
        use the Site.
      </p>

      <h2>1. Use of the Site</h2>
      <h3>1.1 Eligibility:</h3>
      <p>
        You must be at least 18 years old to use the Site. By using the Site, you represent and
        warrant that you meet this age requirement.
      </p>

      <h3>1.2 License:</h3>
      <p>
        We grant you a limited, non-exclusive, non-transferable, and revocable license to use the
        Site for personal, non-commercial purposes. You agree not to use the Site for any unlawful
        or prohibited activities.
      </p>

      <h2>2. User Accounts</h2>
      <h3>2.1 Account Creation:</h3>
      <p>
        You may be required to create an account to access certain features of the Site. You agree
        to provide accurate and complete information when creating your account.
      </p>

      <h3>2.2 Account Security:</h3>
      <p>
        You are responsible for maintaining the confidentiality of your account credentials and for
        all activities that occur under your account. You agree to notify us immediately of any
        unauthorized use of your account.
      </p>

      <h2>3. Content</h2>
      <h3>3.1 User Content:</h3>
      <p>
        You may submit content, such as reviews, comments, and other materials ("User Content"). By
        submitting User Content, you grant us a worldwide, non-exclusive, royalty-free license to
        use, reproduce, modify, and distribute your User Content.
      </p>

      <h3>3.2 Prohibited Content:</h3>
      <p>
        You agree not to submit any User Content that is illegal, harmful, threatening, defamatory,
        obscene, infringing, or otherwise objectionable.
      </p>

      <h2>4. Intellectual Property</h2>
      <h3>4.1 Ownership:</h3>
      <p>
        All content and materials on the Site, including text, graphics, logos, and software, are
        the property of Showrunner or its licensors and are protected by copyright, trademark, and
        other intellectual property laws.
      </p>

      <h3>4.2 Restrictions:</h3>
      <p>
        You may not reproduce, distribute, modify, or create derivative works from any content on
        the Site without our prior written consent.
      </p>

      <h2>5. Disclaimers and Limitation of Liability</h2>
      <h3>5.1 Disclaimers:</h3>
      <p>
        The Site is provided "as is" and "as available" without warranties of any kind, either
        express or implied. We do not warrant that the Site will be uninterrupted, error-free, or
        free from viruses or other harmful components.
      </p>

      <h3>5.2 Limitation of Liability:</h3>
      <p>
        To the fullest extent permitted by law, Showrunner shall not be liable for any indirect,
        incidental, special, or consequential damages arising out of or in connection with your use
        of the Site.
      </p>

      <h2>6. Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of the State of
        New York, without regard to its conflict of law principles. You agree to submit to the
        exclusive jurisdiction of the courts located in New York, New York, for the resolution of
        any disputes arising out of or relating to these Terms or the Site.
      </p>

      <h2>7. Changes to These Terms</h2>
      <p>
        We may update these Terms from time to time. We will notify you of any changes by posting
        the new Terms on the Site. Your continued use of the Site after such changes constitutes
        your acceptance of the new Terms.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at{' '}
        <a href="mailto:info@showrunner.co">info@showrunner.co</a>.
      </p>
    </div>
  );
}

export default LegalTerms;
