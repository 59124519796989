import React from 'react';
import Meta from './../components/Meta';
import AuthSection from './../components/AuthSection';
import { useRouter } from './../util/router';

function AuthPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Auth" />
      <AuthSection
        type={router.query.type}
        providers={['google']}
        afterAuthPath={router.query.next || ''}
      />
    </>
  );
}

export default AuthPage;
