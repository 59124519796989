import React, { useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { deleteList } from './../util/db';
import { XMarkIcon } from '@heroicons/react/24/outline';

function DeleteListModal({ listId, onDone }) {
  const [, setPending] = useState(false);

  const onDelete = () => {
    if (!listId) return;
    setPending(true);

    const query = deleteList(listId);

    query
      .then(() => {
        onDone();
      })
      .catch(error => {
        setPending(false);
        console.error(error);
      });
  };

  return (
    <Transition appear={true} show={true}>
      <Dialog as="div" className="overflow-y-auto fixed inset-0 z-10" onClose={() => onDone()}>
        <div className="px-4 min-h-screen text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 mb-1">
                DELETE SAVED LIST?
              </Dialog.Title>
              <div className="absolute top-0 right-0 p-5 cursor-pointer" onClick={() => onDone()}>
                <XMarkIcon className="w-5" />
              </div>
              <p>This list will be permanently deleted and cannot be recovered.</p>
              <div className="flex justify-between items-center align-middle mt-8">
                <div className="cursor-pointer" onClick={() => onDone()}>
                  <p>Cancel</p>
                </div>
                <button className="btn btn-active" onClick={onDelete}>
                  Delete
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default DeleteListModal;
