import React from 'react';
import { Link } from './../util/router';

function HomeFooter(props) {
  return (
    <footer
      className={`footer sticky bottom-0 bg-white items-center mt-2 p-4 ${
        props.hideMobile && 'hidden sm:grid'
      }`}
    >
      <aside className="grid-flow-col items-center">
        <p>© {new Date().getFullYear()} Showrunner - All right reserved</p>
      </aside>
      <nav className="grid-flow-col gap-2 md:place-self-center md:justify-self-end">
        <Link to="/legal/terms-of-service" className="">
          Terms
        </Link>
        <span aria-hidden="true">·</span>
        <Link to="/legal/privacy-policy" className="">
          Privacy
        </Link>
        <span aria-hidden="true">·</span>
        <Link to="/about" className="">
          About
        </Link>
      </nav>
    </footer>
  );
}

export default HomeFooter;
