import React, { useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { useAuth } from './../util/auth';
import { createList, addShowToList } from './../util/db';

import { LockOpenIcon, LockClosedIcon, XMarkIcon } from '@heroicons/react/24/outline';
import IMG_PLACEHOLDER from '../assets/images/placeholder1.png';

function SaveShowModal({ showId, userLists, onDone }) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [listName, setListName] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);

  const onSubmit = () => {
    if (!listName) return;
    setPending(true);

    const data = {
      owner: auth.user.id,
      list_name: listName,
      shows: [showId],
      is_private: isPrivate,
    };
    const query = createList(data);

    query
      .then(() => {
        onDone();
      })
      .catch(error => {
        setPending(false);
        console.error(error);
      });
  };

  const handleAddShowToList = (listId, shows) => {
    setPending(true);
    const updatedShows = [...shows, showId];
    addShowToList(listId, showId, updatedShows)
      .then(() => {
        onDone();
      })
      .catch(error => {
        setPending(false);
        console.error(error);
      });
  };

  return (
    <Transition appear={true} show={true}>
      <Dialog as="div" className="overflow-y-auto fixed inset-0 z-10" onClose={() => onDone()}>
        <div className="px-4 min-h-screen text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {auth.user ? (
              <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-lg text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6">
                  ADD TO YOUR LISTS
                </Dialog.Title>
                <div className="absolute top-0 right-0 p-5 cursor-pointer" onClick={() => onDone()}>
                  <XMarkIcon className="w-5" />
                </div>

                {userLists.length > 0 ? (
                  <div className="grid grid-cols-2 gap-4 md:gap-8 w-full mt-4">
                    {userLists.map(list => (
                      <div
                        key={list.id}
                        className="w-full min-h-[16rem] md:min-h-[18rem] max-w-[11rem] md:max-w-[19rem] cursor-pointer justify-self-center"
                        onClick={() => handleAddShowToList(list.id, list.shows)}
                      >
                        <div className="w-auto h-4/5 rounded-lg shadow-xl hover:scale-[1.025] overflow-hidden relative">
                          <img
                            className="absolute top-0 left-0 w-full h-full object-cover"
                            alt="list_image"
                            src={
                              list.first_show_data?.image_urls[0]
                                ? list.first_show_data.image_urls[0]
                                : IMG_PLACEHOLDER
                            }
                          />
                        </div>

                        <div className="w-full h-1/5">
                          <div className="mt-2 md:text-xl text-slate-900 uppercase">
                            {list.list_name.length > 18
                              ? `${list.list_name.substring(0, 19)}...`
                              : list.list_name}
                          </div>
                          <div className="flex justify-between">
                            <p className="text-slate-400 text-md">{list.shows.length} saved</p>
                            {list.is_private ? (
                              <LockClosedIcon className="w-4" />
                            ) : (
                              <LockOpenIcon className="w-4" />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>You don't have any lists yet.</p>
                )}

                <hr className="my-3 border-slate-200" />

                <div className="mt-2">
                  <p>Create new list</p>
                  <input
                    className="input input-md w-full border-slate-400 focus:outline-none mt-1 rounded-md"
                    type="text"
                    placeholder="Name"
                    value={listName ?? ''}
                    onChange={e => setListName(e.target.value)}
                  />

                  <div className="flex justify-between py-2">
                    <p>Private</p>
                    <input
                      type="checkbox"
                      className={`toggle toggle-md focus:!outline-none border-slate-400 ${
                        isPrivate ? 'bg-purple ' : 'toggle-unchecked'
                      }`}
                      checked={isPrivate}
                      onChange={() => setIsPrivate(!isPrivate)}
                    />
                  </div>

                  <div className="flex mt-4 justify-center">
                    <button
                      className="btn btn-wide bg-purple text-slate-200"
                      disabled={pending}
                      onClick={() => onSubmit()}
                    >
                      {pending ? '...' : 'Save new list'}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
                Please log in or sign up to save a show.
              </div>
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default SaveShowModal;
