import { useEffect, useState, useCallback, Fragment, useRef } from 'react';
import { useRouter, Link } from '../util/router';
import {
  MapPinIcon,
  BoltIcon,
  CalendarIcon,
  TrashIcon,
  ShareIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Meta from '../components/Meta';
import maplibregl from 'maplibre-gl';
import { Source, Layer, Map, GeolocateControl, NavigationControl } from 'react-map-gl';
import PIN_ICON from '../assets/images/pin.png';
import IMG_PLACEHOLDER from '../assets/images/placeholder1.png';

import { getListById } from '../util/util';
import { removeShowFromList } from '../util/db';
import { formatDate, unclusteredPointLayer } from '../util/data-factory';
import { useWebShare } from '../hooks';
import { useAuth } from '../util/auth';

const SingleListPage = () => {
  const mapRef = useRef(null);
  const router = useRouter();
  const auth = useAuth();
  const { id } = router.query;
  const { shareContent } = useWebShare();

  const [list, setList] = useState();
  const [featureData, setFeatureData] = useState();
  const [initialViewState, setInitialViewState] = useState();
  const [showId, setShowId] = useState();
  const [mapImageInfo, setMapImageInfo] = useState();
  const [canDelete, setCanDelete] = useState(false);
  const [isPrivate, setPrivate] = useState(true);

  const fetchListByID = useCallback(async () => {
    try {
      if (id) {
        const data = await getListById(id);
        setList(data[0]);
        if (auth.user && data[0].owner === auth.user.id) {
          setCanDelete(true);
          setPrivate(false);
        }

        if (!auth.user || data[0].owner !== auth.user.id) {
          if (data[0].is_private) {
            setPrivate(true);
          } else {
            setPrivate(false);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [id, auth.user]);

  useEffect(() => {
    fetchListByID();
  }, [id, fetchListByID]);

  const removeShow = async (listId, showId) => {
    try {
      await removeShowFromList(listId, showId);
      fetchListByID();
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Format filtered data as features to be
   * read as clusters by map-gl. Updates when filtered
   * data changes.
   */
  useEffect(() => {
    if (!list) return;

    const formatted_data = list.shows_data.map(i => {
      let longitude = parseFloat(i.venues.longitude);
      let latitude = parseFloat(i.venues.latitude);
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
        properties: { ...i, lat: latitude, lng: longitude },
      };
    });

    if (!formatted_data) return;
    setFeatureData({ type: 'FeatureCollection', features: formatted_data });
  }, [list]);

  /**
   * Set initial view state for map
   */
  useEffect(() => {
    if (!featureData) return;

    setInitialViewState({
      longitude: featureData.features[0].properties.lng,
      latitude: featureData.features[0].properties.lat,
      zoom: 17,
    });
  }, [featureData]);

  /**
   * Handles logic when click event on markers
   */
  const onPinClick = useCallback(event => {
    const feature = event.features && event.features[0];
    if (!feature) return;
    const layer = feature.layer;

    if (layer.id === 'unclustered-point') {
      const mapboxSource = mapRef.current.getSource('my-data');
      console.debug(mapboxSource);
      event.originalEvent.stopPropagation();
      setShowId(feature.properties.show_id);
      setMapImageInfo(JSON.parse(feature.properties.image_urls));
    } else {
      event.originalEvent.stopPropagation();
    }
  }, []);

  const handleShareClick = () => {
    shareContent({
      files: null,
      title: 'Showrunner',
      text: 'Check this list I created with my favorite shows and galleries',
      url: window.location.href,
    });
  };

  const closeShowMapDialog = () => {
    setShowId(null);
    setMapImageInfo(null);
  };

  if (isPrivate)
    return (
      <Fragment>
        <Meta title="Single List" />
        <section className="w-screen h-[100px] overflow-hidden bg-black/90"></section>
        <div className="flex sm:flex-row w-screen mt-2 h-[calc(100vh-100px)] overflow-hidden">
          <h2 className="text-center my-auto mx-auto text-xl">
            Oh no, seems like this is a private list of shows.
          </h2>
        </div>
      </Fragment>
    );

  return (
    <Fragment>
      <Meta title="Single List" />
      <section className="w-screen h-[100px] overflow-hidden bg-black/90"></section>

      {!isPrivate && (
        <div className="flex flex-col sm:flex-row w-screen mt-2 h-[calc(100vh-100px)] overflow-hidden">
          <div className="w-full sm:w-[60%] max-w-[90rem] md:p-4 justify-center mx-auto">
            <div className="flex w-full py-2 px-3 my-auto mx-auto justify-between">
              <h1 className="uppercase text-2xl md:text-4xl font-medium opacity-50">
                {list?.list_name ?? ''}
              </h1>
              <button className="btn btn-sm mx-2 my-auto" onClick={handleShareClick}>
                <ShareIcon className="w-4" />
              </button>
            </div>

            {/* List */}
            <div className={`sm:grid sm:grid-cols-2 gap-10 h-48 sm:h-auto overflow-auto px-1`}>
              {list &&
                list.shows_data.map(show => (
                  <div
                    key={show.show_id}
                    className={`p-3 bg-gray-100 overflow-auto uppercase font-medium text-black leading-10 border-b ${
                      showId === show.show_id
                        ? 'border-4 border-b-4 bg-white rounded-lg border-regal-blue'
                        : ''
                    }`}
                  >
                    <Link href="/show/[id]" to={`/show/${show.show_id}`}>
                      <h1 className="text-md md:text-2xl my-1">
                        {show.artist_count > 1
                          ? show.show_title?.substring(0, 90) ?? 'Untitled'
                          : show.artist_names[0]}
                      </h1>
                    </Link>
                    <p className="text-red text-xs md:text-lg">{show.top_tags.join(' | ')}</p>

                    <div className="flex justify-between text-xs md:text-sm my-2 sm:my-4 items-center">
                      <div className="flex flex-1 flex-wrap capitalize">
                        <div className="flex mr-4 py-1 items-center">
                          <MapPinIcon className="w-5" /> {show.venues?.venue_name}
                        </div>
                        {show.recommended ? (
                          <div className="flex mr-4 py-1 items-center">
                            <BoltIcon className="w-5" /> Trending
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="flex mr-4 py-1 items-center">
                          <CalendarIcon className="w-5 mr-1" /> {formatDate(show.start_date)} -{' '}
                          {formatDate(show.end_date)}
                        </div>
                      </div>
                      {canDelete && (
                        <div
                          className="flex-none cursor-pointer items-center"
                          onClick={() => removeShow(list.id, show.show_id)}
                        >
                          <TrashIcon className="w-5 text-dark-red" />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {/* Map */}
          <div className="flex relative w-full sm:w-[40%] h-full max-w-[90rem] md:p-0 justify-center mx-auto">
            {mapImageInfo && (
              <div className="absolute top-3 left-3 h-32 w-32 md:h-40 md:w-40 z-40">
                <div
                  className="absolute top-2 right-2 z-30 w-6 h-6 p-1 bg-white rounded-xl cursor-pointer"
                  onClick={closeShowMapDialog}
                >
                  <XMarkIcon className="my-auto" />
                </div>
                <div className="bg-white h-auto rounded-xl overflow-hidden relative p-2">
                  <div className="h-20 md:h-32">
                    <img
                      className="h-[95%] mx-auto object-cover"
                      alt="_image"
                      src={mapImageInfo.length > 0 ? mapImageInfo[0] : IMG_PLACEHOLDER}
                    />
                  </div>
                </div>
              </div>
            )}

            {list && list.shows_data.length && initialViewState && (
              <Map
                ref={mapRef}
                initialViewState={initialViewState}
                mapLib={maplibregl}
                mapStyle="https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json"
                interactiveLayerIds={[unclusteredPointLayer.id]}
                minZoom={1.25}
                maxZoom={20}
                onClick={onPinClick}
                onLoad={async () => {
                  const map = mapRef.current.getMap();
                  if (!map.hasImage('pin-icon')) {
                    const image = await map.loadImage(PIN_ICON);
                    map.addImage('pin-icon', image.data);
                  }
                }}
              >
                <GeolocateControl />
                <NavigationControl />
                <Source
                  id="my-data"
                  type="geojson"
                  data={featureData}
                  clusterMaxZoom={11}
                  clusterRadius={100}
                >
                  <Layer {...unclusteredPointLayer} />
                </Source>
              </Map>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SingleListPage;
