import React from 'react';

function LegalPrivacy(props) {
  return (
    <div className="prose prose-a:text-blue-600 max-w-none">
      <h1>Privacy Policy</h1>
      <p>
        <strong>Last Updated:</strong> September 28, 2024
      </p>

      <p>
        Showrunner ("we", "us", "our") is committed to protecting your privacy. This Privacy Policy
        explains how we collect, use, disclose, and safeguard your information when you visit our
        website <a href="http://www.showrunner.co">www.showrunner.co</a> (the "Site") and use our
        services. If you do not agree with the terms of this Privacy Policy, please do not access
        the Site.
      </p>

      <h2>1. Information We Collect</h2>
      <h3>1.1 Personal Information:</h3>
      <p>
        We may collect personally identifiable information, such as your name, email address, and
        other contact details when you register on our Site, subscribe to our newsletter, or contact
        us.
      </p>

      <h3>1.2 Non-Personal Information:</h3>
      <p>
        We may collect non-personal information about you whenever you interact with our Site. This
        may include your browser type, device type, pages visited, and other similar information.
      </p>

      <h2>2. How We Use Your Information</h2>
      <h3>2.1 To Provide and Improve Services:</h3>
      <p>
        We use the information we collect to provide, maintain, and improve our services, including
        personalizing your experience on the Site.
      </p>

      <h3>2.2 To Communicate:</h3>
      <p>
        We may use your email address to send you updates, newsletters, and other communications
        related to our services. You can opt out of receiving these communications at any time.
      </p>

      <h3>2.3 To Analyze Usage:</h3>
      <p>
        We use non-personal information to understand how our users interact with the Site, which
        helps us improve the Site's functionality and user experience.
      </p>

      <h2>3. How We Share Your Information</h2>
      <h3>3.1 Service Providers:</h3>
      <p>
        We may share your information with third-party service providers who assist us in operating
        our Site and providing our services.
      </p>

      <h3>3.2 Legal Requirements:</h3>
      <p>
        We may disclose your information if required to do so by law or in response to valid
        requests by public authorities (e.g., a court or government agency).
      </p>

      <h3>3.3 Business Transfers:</h3>
      <p>
        In the event of a merger, acquisition, or sale of all or a portion of our assets, your
        information may be transferred as part of that transaction.
      </p>

      <h2>4. Data Security</h2>
      <p>
        We use administrative, technical, and physical security measures to help protect your
        personal information. While we have taken reasonable steps to secure the personal
        information you provide to us, please be aware that no security measures are perfect or
        impenetrable.
      </p>

      <h2>5. Your Rights and Choices</h2>
      <h3>5.1 Access and Correction:</h3>
      <p>
        You have the right to access and update your personal information. You can do this by
        contacting us at <a href="mailto:info@showrunner.co">info@showrunner.co</a>.
      </p>

      <h3>5.2 Data Deletion:</h3>
      <p>
        You can request that we delete your personal information by contacting us at{' '}
        <a href="mailto:info@showrunner.co">info@showrunner.co</a>. We will take reasonable steps to
        delete your information, except where we are required to retain it by law.
      </p>

      <h3>5.3 Opt-Out:</h3>
      <p>
        You can opt out of receiving promotional communications from us by following the unsubscribe
        instructions included in those communications or by contacting us at{' '}
        <a href="mailto:info@showrunner.co">info@showrunner.co</a>.
      </p>

      <h2>6. Cookies and Tracking Technologies</h2>
      <p>
        We may use cookies and similar tracking technologies to collect non-personal information
        about your interactions with the Site. You can manage your cookie preferences through your
        browser settings.
      </p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you of any changes by
        posting the new Privacy Policy on this page. The date of the latest revision will be
        indicated at the top of the page.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at{' '}
        <a href="mailto:info@showrunner.co">info@showrunner.co</a>.
      </p>
    </div>
  );
}

export default LegalPrivacy;
